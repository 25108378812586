.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: min(2000px, 80vmin);
  pointer-events: none;
}

.App-logo-wrapper {
  width: 100vw;
  height: 80vh;
}

.App-header {
  min-height: 20vh;
  max-width: 80vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(10px + 3vmin);
  color: white;
  padding: 0 5%;
}

.App-header h1,h6 {
  margin: 0 10%;
}